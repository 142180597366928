import React, { Component } from "react";
import { render } from "react-dom";
import { ReactComponent as Logo } from "./images/logo2.svg";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const svg_animation_time = "60s";
    const svg_animation_gradient_point = 60 + "s";
    return (
      <div>
        <a
          href="mailto:christen@bettergood.us"
          className="logo"
          alt="Make first contact"
        >
          <Logo  alt="Make first contact" />
        </a>

        <svg class="gradient" xmlns="http://www.w3.org/2000/svg">
          <rect fill="url(#gradient)" width="100%" height="100%" />

          <defs>
            <radialGradient
              id="gradient"
              cx="17%"
              cy="17%"
              r="80%"
              fx="17%"
              fy="17%"
              gradientTransform="translate(0 0)"
            >
              <animateTransform
                attributeName="gradientTransform"
                attributeType="XML"
                type="translate"
                values="0.3, 0.3"
                repeatCount="indefinite"
                fill="freeze"
                dur={svg_animation_gradient_point}
              />

              <stop offset="0%" stop-color="#6DD9BF">
                <animate
                  attributeName="stop-color"
                  values="#6DD9BF;#FFCC63;#FFFFFF;#FF0000;#B721FF;#08AEEA;#FF3CAC;#2B86C5;#F4D03F;#4158D0;#FFCC70"
                  dur={svg_animation_time}
                  repeatCount="indefinite"
                />
              </stop>

              <stop offset="200%" stop-color="#FF714F">
                <animate
                  attributeName="stop-color"
                  values="#FF714F;#9BC1BC;#6284FF;#21D4FD;#2AF598;#784BA0;#16A085;#C850C0"
                  dur={svg_animation_time}
                  repeatCount="indefinite"
                />
              </stop>
            </radialGradient>
          </defs>
        </svg>
      </div>
    );
  }
}

export function renderToDOM(container) {
  render(<App />, container);
}

